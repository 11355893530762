
import { defineComponent, defineAsyncComponent, PropType } from 'vue';
import { CouponDataI } from '@/modules/coupon/interfaces/digital-coupon.interface';

export default defineComponent({
  name: 'Iris',
  props: {
    data: {
      type: Object as PropType<CouponDataI>,
      required: true,
    },
  },
  components: {
    PaymentDetail: defineAsyncComponent(
      () => import('@/modules/coupon/partials/PaymentDetail.vue')
    ),
    Channels: defineAsyncComponent(
      () => import('@/modules/coupon/partials/Channels.vue')
    ),
    Footer: defineAsyncComponent(
      () => import('@/modules/coupon/partials/Footer.vue')
    ),
  },
});
